::-webkit-calendar-picker-indicator {
  /* background: white; */
  opacity: 50%;
  transition: 0.5s;
}
::-webkit-calendar-picker-indicator:hover {
  /* background: white; */
  opacity: 100%;
}

input[type="date"] {
  color: black;
}

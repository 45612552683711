/* Hide scrollbar for Chrome, Safari and Opera */
.tags::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tags {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input[type="text"] {
  -webkit-box-shadow: 0 0 0 white inset !important;
  color: black;
}

input[type="date"] {
  -webkit-box-shadow: 0 0 0 white inset !important;
}

.container-component {
  position: absolute;
  background-color: black;
}

.center-component {
  display: flex;

  width: 100vw;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ff000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4c157c;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #41106c;
}

::-webkit-scrollbar-track-piece {
  background: #653072;
}

::-webkit-scrollbar-corner {
  background: #653072;
}
